import { RadioGroup } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Tooltip from "components/Tooltip";
import { RadioButtonFilled } from "./RadioButtonFilled";

const RadioButtonGroup = (props: any) => {
  const {
    disabled,
    label,
    options,
    onChange,
    value = { value: "", label: "" },
    rowAlign = false,
    required,
    tooltip,
  } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const inputRef = useRef<any>(null);
  inputRef?.current?.setCustomValidity(
    required && !selectedValue?.value ? `This is a required field` : "",
  );

  const updateValue = (value: any) => {
    onChange(value);
    setSelectedValue(value);
  };

  useEffect(() => {
    if (value?.value !== selectedValue?.value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (required && !selectedValue?.value) {
      inputRef.current?.setCustomValidity(`This is a required field`);
    } else inputRef.current?.setCustomValidity("");
  }, [selectedValue]);

  return (
    <div className="flex">
      <RadioGroup
        name={label}
        defaultValue={selectedValue ? selectedValue?.value : null}
        value={selectedValue?.value}
        disabled={disabled}
        data-testid="radio"
      >
        {(label || required) && (
          <RadioGroup.Label className="group group-hover:cursor-pointer items-center leading-normal font-normal text-sm flex mb-[0.5rem]">
            {label}
            {required && "*"}
            {tooltip && <Tooltip tooltipId={label} content={tooltip} />}
            <input
              ref={inputRef}
              type="radio"
              value={""}
              className="opacity-0 pointer-events-none"
              data-testid={"hidden_input"}
            />
          </RadioGroup.Label>
        )}
        <div
          className={classNames(
            "relative w-full flex flex-wrap gap-1",
            !rowAlign && "flex-col",
          )}
        >
          {options?.length > 0 ? (
            options.map((input: any, index: number) => {
              const isSelected = input.value === selectedValue?.value;
              return (
                <RadioGroup.Option
                  value={input.value}
                  as={Fragment}
                  key={"radio" + index}
                >
                  <div
                    data-testid={`radio-option-${input.value}`}
                    className={`px-[0.5rem] gap-[0.75rem] min-w-36 h-[2.5rem] last:mb-0 flex items-center bg-white`}
                    onClick={() => updateValue(input)}
                  >
                    <div className="flex items-center w-[1.25rem] h-[1.25rem]">
                      <div
                        className={classNames(
                          "flex items-center",
                          isSelected
                            ? 'border-["#0A7AFF"]'
                            : "border-[#818181]",
                        )}
                      >
                        {isSelected ? (
                          <RadioButtonFilled />
                        ) : (
                          <div className="rounded-[0.625rem] w-[1.25rem] h-[1.25rem] border-[2px] border-solid border-[#818181]"></div>
                        )}
                      </div>
                    </div>
                    <RadioGroup.Label
                      as="span"
                      className={`${
                        isSelected ? "text-[#0A7AFF]" : "text-[#111827]"
                      } block text-xs`}
                    >
                      {input.label}
                    </RadioGroup.Label>
                  </div>
                </RadioGroup.Option>
              );
            })
          ) : (
            <div className="border py-1 px-2 mb-2 last:mb-0  rounded-md flex items-center justify-between ">
              <span className="block text-xs opacity-25">Option 1</span>
              <div className="rounded-full w-6 h-6 p-1 border ml-2"></div>
            </div>
          )}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioButtonGroup;
