import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { get } from "lodash";

interface frm_form_field {
  key: String;
  value: String;
}
export const defaultBlock = {
  type: { label: "One", value: "w-full", no_column: 1, category: "LAYOUT" },
  blocks: [null],
};

export const defaultLayout = [
  {
    type: { label: "One", value: "w-full", no_column: 1, category: "TITLE" },
    blocks: [
      {
        properties: {
          label: "Submit",
          name: "button",
        },
        type: "button",
      },
      {
        properties: {
          size: { label: "Heading 1", value: "text-3xl" },
          label: "Add your label",
          value: "Form Title",
        },
        type: "text",
      },
    ],
  },
  { ...defaultBlock },
];

export const rootUrl =
  process.env.REACT_APP_BASE_URL || "https://top-api.talview.org";

export const updateFormBuilderApi = createApi({
  reducerPath: "form_id",
  baseQuery: graphqlRequestBaseQuery({
    url: `${rootUrl}/v1/graphql`,
    prepareHeaders: (headers) => {
      const { access_token, default_role, tenant_id } = JSON.parse(
        localStorage.getItem("tv.frm") || "{}",
      );
      if (access_token && tenant_id)
        headers.set("Authorization", `Bearer ${access_token}`);
      if (default_role) headers.set("x-hasura-role", default_role);
      return headers;
    },
  }),
  tagTypes: ["DRAFT", "PUBLISHED"],

  endpoints: (builder: any) => ({
    exchangeToken: builder.mutation({
      query: ({ token }: { token: string }) => ({
        document: gql`
          mutation ($token: String!) {
            auth_get_exchange_token(token: $token) {
              access_token
              custom_token
            }
          }
        `,
        variables: {
          token,
        },
      }),
    }),
    getFormDraft: builder.query({
      query: ({ id }: any) => ({
        document: gql`
            query MyQuery {
              frm_form_by_pk(id: ${id}) {
                form_versions(where: {status: {_eq: DRAFT}}) {
                  structure
                }
                id
                name
                type
              }
            }
          `,
      }),
      providesTags: ["DRAFT"],
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { name, type, form_versions },
        } = response || {};
        return { name, type, structure: get(form_versions, "0.structure") };
      },
    }),
    getFormStructure: builder.query({
      query: ({ id }: { id: number }) => ({
        document: gql`
                query {
                  frm_form_by_pk(id: ${id}) {
                    id
                    form_versions(where: {status: {_eq: PUBLISHED}}) {
                      id
                      structure
                      status
                      version
                    }
                    type
                  }
                }
          `,
      }),
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { type, form_versions },
        } = response || {};
        return { type, structure: get(form_versions, "0.structure") };
      },
      providesTags: ["PUBLISHED"],
    }),
    getSelectFieldTypes: builder.query({
      query: () => ({
        document: gql`
          query {
            frm_select_field_type {
              id
              name
            }
          }
        `,
      }),
    }),
    getSelectFieldData: builder.query({
      query: (id: number) => ({
        document: gql`
          query ($id: Int!) {
            frm_select_field_data(
              where: { select_field_type_id: { _eq: $id } }
            ) {
              key
              value
              id
            }
          }
        `,
        variables: { id },
      }),
    }),
    createForm: builder.mutation({
      query: ({ name, type }: { name: string; type: string }) => ({
        document: gql`
          mutation ($name: String!, $type: String!) {
            frm_create_form(name: $name, type: $type) {
              data {
                id
                name
                type
              }
            }
          }
        `,
        variables: {
          name,
          type,
        },
      }),
    }),
    updateForm: builder.mutation({
      query: ({ form_id, structure }: { form_id: string; structure: any }) => {
        return {
          document: gql`
            mutation ($form_id: Int!, $structure: jsonb!) {
              frm_update_form_version(
                form_id: $form_id
                structure: $structure
              ) {
                form_id
                structure
              }
            }
          `,
          variables: {
            form_id,
            structure: structure,
          },
        };
      },
    }),
    publishForm: builder.mutation({
      query: ({ form_id }: { form_id: number }) => ({
        document: gql`
          mutation ($form_id: Int!) {
            frm_publish_form(form_id: $form_id) {
              form_id
              status
              structure
            }
          }
        `,
        variables: {
          form_id,
        },
      }),
      invalidatesTags: ["PUBLISHED"],
    }),
    saveFormInstance: builder.mutation({
      query: ({
        formFieldsData,
        formId,
      }: {
        formFieldsData: frm_form_field[];
        formId: number;
      }) => ({
        document: gql`
          mutation ($formFieldsData: [frm_form_field!]!, $formId: Int!) {
            frm_form_instance_save(
              form_data: $formFieldsData
              form_id: $formId
            ) {
              id
            }
          }
        `,
        variables: {
          formFieldsData,
          formId,
        },
      }),
    }),
  }),
});

export const {
  useExchangeTokenMutation,
  useGetFormDraftQuery,
  useGetFormStructureQuery,
  useGetSelectFieldTypesQuery,
  useGetSelectFieldDataQuery,
  useCreateFormMutation,
  useUpdateFormMutation,
  usePublishFormMutation,
  useSaveFormInstanceMutation,
} = updateFormBuilderApi;
