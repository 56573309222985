import React, { useEffect, useRef, useState, useMemo, memo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateBlockNote } from "@blocknote/react";
import "@blocknote/core/fonts/inter.css";
import {
  useGetFormStructureQuery,
  useSaveFormInstanceMutation,
} from "store/services/updateFormBuilder";
import { Data } from "Pages/Editor";
import { DEVICE_TYPE, FORM_TYPE, ROUTE_TYPE } from "utils/constant";
import {
  initForm,
  initobj,
  postFormData,
  saveFormInstance,
} from "containers/BlockNoteRenderer/utils/formDataHelpers";
import {
  initBlocksRender,
  renderContent,
} from "containers/BlockNoteRenderer/utils/renderHelpers";
import Loader from "components/Loader";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";
import { schema } from "components/BlockNoteEditor";
import {
  DeviceType,
  FieldData,
  FormRendererProps,
  HtmlBlock,
  LogicProps,
} from "./types";

const BlockNoteRenderer: React.FC<FormRendererProps> = ({
  setFormState,
  formType,
  formContent,
}) => {
  const { id } = useParams() || {};
  const [searchParams] = useSearchParams();
  const editor = useCreateBlockNote({ schema });

  const [deviceType, setDeviceType] = useState<DeviceType>(DEVICE_TYPE.DESKTOP);
  const [htmlContent, setHtmlContent] = useState<HtmlBlock[]>([]);
  const logicsRef = useRef<LogicProps[]>([]);
  const [formData, setFormData] = useState<FieldData>({});
  const [formFileData, setFormFileData] = useState<FieldData[]>([]);
  const [blocksLayout, setBlocksLayout] = useState<number[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const [saveForm] = useSaveFormInstanceMutation();
  const { data, status } = useGetFormStructureQuery(
    {
      id,
      state: ROUTE_TYPE.RENDERER,
    },
    { skip: !!formType },
  );

  const { type, structure } = (data || {}) as Data;
  const formTypeValue = type || formType;
  const content = structure?.doc || formContent?.doc || [{}];
  const btnProps = structure?.button?.props ||
    formContent?.button?.props || { label: "" };

  useEffect(() => {
    initForm(searchParams, initobj);
    const eventMethod = "addEventListener";
    const eventer = window[eventMethod];
    const messageEvent = "message";

    eventer(messageEvent, (event) => {
      //Trigger formSubmit from parent (For Recruit)
      if (event.data.type === "submitEvaluationForm") {
        document.getElementById("submitButton")?.click();
      }
    });
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      const { htmlBlocks, layout, conditionalLogics } = await initBlocksRender(
        content,
        setFormFileData,
        setFormData,
        editor,
        logicsRef,
      );
      setHtmlContent(htmlBlocks);
      setBlocksLayout(layout);
      logicsRef.current = conditionalLogics;
    })();
  }, [content]);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    postFormData(formData, formRef);
    saveFormInstance({
      formData,
      id: Number(id),
      formFileData,
      saveForm,
    });
  };

  const grids = useMemo(
    () => renderContent(htmlContent, blocksLayout, deviceType),
    [htmlContent, blocksLayout, deviceType],
  );

  return (
    <div className="max-h-screen w-full min-h-screen bg-white block-render">
      {formType && (
        <div className="cursor-pointer w-full flex justify-end p-5 bg-white">
          <div className="mr-3">
            <Toggle
              labelClassNames="text-gray-500"
              label={"Mobile View"}
              onChange={(checked: boolean) => {
                if (checked) setDeviceType(DEVICE_TYPE.MOBILE);
                else setDeviceType(DEVICE_TYPE.DESKTOP);
              }}
              size="sm"
              checked={deviceType === DEVICE_TYPE.MOBILE}
            />
          </div>
          <div
            role="button"
            data-testid="back-to-editor"
            onClick={() => setFormState?.(ROUTE_TYPE.BUILDER)}
            className="text-sm font-light	text-gray-500	"
            title="Back to Editor"
          >
            <FontAwesomeIcon icon={solid("right-from-line")} />
            Back to Editor
          </div>
        </div>
      )}
      <div
        className={classNames(
          "mx-auto mt-2 px-5 py-10 bg-white overflow-auto",
          formType
            ? "h-[85vh] max-h-[85vh] " +
                (deviceType === DEVICE_TYPE.MOBILE
                  ? "max-w-sm shadow-lg"
                  : "max-w-6xl")
            : "max-h-screen h-screen",
        )}
      >
        <form
          ref={formRef}
          onSubmit={onFormSubmit}
          id={
            formTypeValue === FORM_TYPE.REGISTRATION
              ? "assessmentForm"
              : "evaluationForm"
          }
        >
          {status === "pending" ? (
            <div className="h-full flex items-center justify-center grow">
              <Loader />
            </div>
          ) : (
            <div className="bn-container">{grids}</div>
          )}
          <div className="w-100 flex justify-center mt-4">
            <button
              id="submitButton"
              className={`border rounded py-2 px-3 bg-blue-700 ${
                formTypeValue !== FORM_TYPE.REGISTRATION && "hidden"
              } text-white text-sm font-medium text-center`}
              type="submit"
            >
              {btnProps?.label || "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(BlockNoteRenderer);
