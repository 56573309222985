/**
 *
 * Checkbox
 *
 */

import { memo } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import classNames from "classnames";
import MarkdownWrapper from "components/MarkdownWrapper";

const sizeMap = {
  xs: "h-3 w-3",
  sm: "h-3 w-3",
  md: "h-4 w-4",
  lg: "h-5 w-5",
};
function CheckBox(props: any) {
  const {
    id,
    name,
    onChange,
    size,
    label,
    disabled,
    checked,
    required,
    inputRef,
  } = props;

  return (
    <div className="w-full px-[0.5rem] py-[0.38rem] flex align-middle items-center">
      <input
        data-testid={`checkbox-input-${id}`}
        id={`checkbox-${id}`}
        name={`${name}`}
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        className={classNames(
          get(sizeMap, size, "h3 w-3"),
          disabled
            ? "text-zinc-300 border-zinc-300"
            : "text-blue-600 focus:ring-blue-500 border-gray-500 ",
          "rounded-sm",
        )}
        disabled={disabled}
        checked={checked}
        value={checked}
      />
      {required && (
        <input
          ref={inputRef}
          className="absolute opacity-0 pointer-events-none"
          role="button"
        />
      )}
      {label && (
        <label
          data-testid="checkbox-label"
          htmlFor={`checkbox-${name}`}
          className={classNames(
            "ml-2 text-sm",
            disabled ? "text-zinc-300 " : "text-gray-700",
          )}
        >
          <MarkdownWrapper prose>
            {required ? label + "*" : label}
          </MarkdownWrapper>
        </label>
      )}
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xs"]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

CheckBox.defaultProps = {
  size: "md",
};
export default memo(CheckBox);
