/**
 *
 * DatePicker
 *
 */

import { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Datepicker from "tailwind-datepicker-react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarLines } from "@fortawesome/pro-light-svg-icons";
import { Input } from "components/Tools/Input";
import { useOnClickOutside } from "usehooks-ts";
import { CloseIcon } from "images/CloseIcon";

export const toDateString = (e: Date) =>
  e.toLocaleDateString("en-uk", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const DatePicker = (props: any) => {
  const {
    onChange,
    label,
    placeholder,
    disabled,
    readOnly,
    value,
    attrkey,
    required,
    min,
    max,
    error,
    hasToggle,
    isEnabled,
    fieldEnableChange,
    tooltip,
  } = props;
  const [val, setValue] = useState(value);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const options = {
    title: "",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    maxDate: max && new Date(max),
    minDate: min && new Date(min),
    theme: {
      background: "",
      todayBtn: "",
      clearBtn: "false",
      icons: "",
      text: "", //"px-2.5 text-gray-700 text-sm font-medium leading-6 text-center",
      disabledText: "px-2.5 text-gray-400",
      input: "",
      inputIcon: "",
      selected:
        "px-2.5 text-blue-600 bg-blue-100 text-white hover:bg-blue-600 hover:text-white rounded-full",
    },
    datepickerClassNames: "relative top-0",
    weekDays: ["M", "T", "W", "T", "F", "S", "S"],
    icons: {
      prev: () => <ChevronLeftIcon className="h-5 w-5" />,
      next: () => <ChevronRightIcon className="h-5 w-5" />,
    },
    language: "en",
  };
  const handleClose = (state: boolean) => {
    onChange && onChange(state);
    setShow(false);
  };

  useEffect(() => {
    if (value !== val) setValue(value);
  }, [value]);

  const handleClickOutside = () => {
    setShow(false);
  };
  useOnClickOutside(ref, handleClickOutside);
  return (
    <div className="flex light" ref={ref}>
      <Datepicker
        options={options}
        show={show}
        setShow={handleClose}
        onChange={(e) => {
          setValue(toDateString(e));
          onChange(toDateString(e));
        }}
      >
        <div
          className="h-max items-center"
          onClick={() => !disabled && !readOnly && setShow(!show)}
        >
          <Input
            type="text"
            error={error}
            placeholder={placeholder}
            value={val}
            disabled={disabled}
            label={label}
            name={attrkey}
            readOnly={true}
            required={required}
            data-testid="datepicker"
            trailingAddon={
              !value ? (
                <FontAwesomeIcon icon={faCalendarLines} />
              ) : (
                <div data-testid="clear">
                  <CloseIcon />
                </div>
              )
            }
            trailingIconOnClick={(e) => {
              setValue("");
              onChange("");
              e.stopPropagation();
            }}
            isEnabled={isEnabled}
            fieldEnableChange={fieldEnableChange}
            hasToggle={hasToggle}
            tooltip={tooltip}
          />
        </div>
      </Datepicker>
    </div>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  nullable: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  attrkey: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
};

DatePicker.defaultProps = {
  nullable: true,
  disabled: false,
};

export default memo(DatePicker);
