import { FormStateType } from "Pages/Editor";

export const FORM_TYPE = {
  REGISTRATION: "REGISTRATION",
  EVALUATION: "EVALUATION",
};

export const DEVICE_TYPE = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
};

export const ROUTE_TYPE: Record<string, FormStateType> = {
  BUILDER: "builder",
  RENDERER: "renderer",
};
