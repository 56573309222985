import React, { memo } from "react";
import {
  DragHandleMenu,
  RemoveBlockItem,
  AddBlockButton,
  DragHandleButton,
  SideMenu as BlockNoteSideMenu,
} from "@blocknote/react";
import { Block } from "@blocknote/core";
import _, { pickBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import MenuBox from "components/Popover/MenuPopover/MenuBox";
import {
  ConditionLogicBtn,
  HideAndShowBlock,
} from "components/Tools/CustomBlocks/SideMenuOptions";
import { propertyBox, validationBox } from "components/Block/blockMenuList";
import {
  customBlocksTypes,
  logicSupported,
} from "components/Tools/CustomBlocks";
import { schema } from "components/BlockNoteEditor";

export const fetchProperties = (fieldProps: any) => {
  return _.reduce(
    fieldProps,
    (result, item) => {
      if (!item?.error) {
        const value =
          item?.hasToggle && item?.isEnabled === false ? null : item?.value;
        _.set(result, item?.key, value);
      }
      return result;
    },
    {},
  );
};

export const onMenuclose = (
  block: Block,
  unfreezeMenu: () => void,
  menuFieldsRef: any,
  editor: typeof schema.BlockNoteEditor,
) => {
  const fieldProps = menuFieldsRef?.current;
  if (!fieldProps) return;
  const properties = fetchProperties(fieldProps);

  const updatedToggleState = Object.fromEntries(
    fieldProps
      .filter((item: any) => item?.hasToggle)
      .map((item: any) => [item?.key, item?.isEnabled ?? false]),
  );

  try {
    editor?.updateBlock(block, {
      props: { ...properties, toggleState: updatedToggleState },
    });
  } catch (error) {
    console.error("Error updating block properties", error);
  }
  unfreezeMenu();
  menuFieldsRef.current = null;
};

interface SideMenuProps {
  block: Block;
  unfreezeMenu: () => void;
  addBlock: (block: Block) => void;
  editor: typeof schema.BlockNoteEditor;
  menuFieldsRef: any;
  selectFieldTypes: any[];
}

const SideMenu: React.FC<SideMenuProps> = (props: any) => {
  const {
    block,
    unfreezeMenu,
    addBlock,
    editor,
    menuFieldsRef,
    selectFieldTypes,
  } = props || {};
  const { props: blockProps, type } = block || {};
  const isCustomBlock =
    Object.keys(customBlocksTypes)?.includes(type) &&
    type !== customBlocksTypes.logic;
  const filteredProps = pickBy(blockProps, (value) => value !== null);

  return (
    <BlockNoteSideMenu {...props}>
      <FontAwesomeIcon
        icon={faTrashCan}
        className="text-gray-400 cursor-pointer hover:bg-gray-200 rounded-md p-1.5"
        onClick={() => editor?.removeBlocks([block])}
      />
      <AddBlockButton addBlock={addBlock} />
      <DragHandleButton
        {...props}
        unfreezeMenu={() =>
          onMenuclose(block, unfreezeMenu, menuFieldsRef, editor)
        }
        dragHandleMenu={(props) => {
          return (
            <DragHandleMenu {...props}>
              {isCustomBlock && (
                <>
                  <MenuBox
                    propertyBox={propertyBox[type]}
                    validations={validationBox[type]}
                    block={{ type, properties: filteredProps }}
                    menuFieldsRef={menuFieldsRef}
                    selectFieldTypes={selectFieldTypes}
                  />
                  <div className="border-[0.5px] border-gray-200"></div>
                </>
              )}
              <RemoveBlockItem {...props}>
                <div>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="text-gray-600 mr-2"
                  />
                  Delete
                </div>
              </RemoveBlockItem>
              <HideAndShowBlock block={block} blockProps={blockProps} />
              {logicSupported?.includes(type) && (
                <ConditionLogicBtn
                  block={block}
                  menuFieldsRef={menuFieldsRef}
                />
              )}
            </DragHandleMenu>
          );
        }}
      />
    </BlockNoteSideMenu>
  );
};

export default memo(SideMenu);
