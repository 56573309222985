import React, { ReactElement } from "react";
import { forEach, get } from "lodash";
import classNames from "classnames";
import { getElement } from "utils/getElement";
import { DEVICE_TYPE } from "utils/constant";
import { customBlocksTypes } from "components/Tools/CustomBlocks";
import { schema } from "components/BlockNoteEditor";
import {
  ContentItem,
  DeviceType,
  FieldData,
  HtmlBlock,
  LogicProps,
} from "../types";
import { postFormFile } from "./formDataHelpers";
import { applyConditionalLogic } from "./conditionalLogic";

export const onChangeInBlock = (
  type: keyof typeof customBlocksTypes,
  res: any,
  attrkey: string,
  setFormFileData: (
    value: ((prevState: FieldData[]) => FieldData[]) | FieldData[],
  ) => void,
  setFormData: (
    value: ((prevState: FieldData) => FieldData) | FieldData,
  ) => void,
  logicsRef: React.MutableRefObject<LogicProps[]>,
) => {
  if (type === customBlocksTypes.file) {
    const file = get(res, "files.0");
    postFormFile(file, attrkey, setFormFileData);
  } else {
    setFormData((data) => {
      return {
        ...data,
        [attrkey]: res,
      };
    });
    applyConditionalLogic(logicsRef.current, attrkey, res?.value);
  }
};

export const initBlocksRender = async (
  content: Array<Record<string, any>>,
  setFormFileData: (
    value: ((prevState: FieldData[]) => FieldData[]) | FieldData[],
  ) => void,
  setFormData: (
    value: ((prevState: FieldData) => FieldData) | FieldData,
  ) => void,
  editor: typeof schema.BlockNoteEditor,
  logicsRef: React.MutableRefObject<LogicProps[]>,
) => {
  const htmlBlocks: HtmlBlock[] = [];
  const layout: number[] = [];
  const conditionalLogics = [];
  for (const item of content) {
    const { type, props } = (item as ContentItem) || {};
    let blockElement = null;
    if (type === customBlocksTypes.logic) {
      conditionalLogics.push(props);
      continue;
    }
    if (customBlocksTypes[type]) {
      const { attrkey, label, hide } = props || {};
      const element = React.cloneElement(getElement(type), {
        ...props,
        onChange: (res: any) => {
          if (attrkey)
            onChangeInBlock(
              type,
              res,
              attrkey,
              setFormFileData,
              setFormData,
              logicsRef,
            );
        },
      });
      layout.push(props?.layout?.value || 1);
      blockElement = (
        <div id={attrkey || label} className={classNames(hide && "hidden")}>
          {element}
        </div>
      );
    } else {
      blockElement = await editor?.blocksToFullHTML([item]);
      layout.push(1);
    }
    htmlBlocks.push(blockElement);
  }
  return { htmlBlocks, layout, conditionalLogics };
};

export const getContent = (contentItem: HtmlBlock) => {
  if (typeof contentItem === "string") {
    return (
      <div
        className="bn-default-styles"
        dangerouslySetInnerHTML={{ __html: contentItem }}
      />
    );
  }
  if (React.isValidElement(contentItem)) {
    return <div className="w-full p-0.5 mb-3">{contentItem}</div>;
  }
  return <></>;
};

export const renderContent = (
  htmlContent: HtmlBlock[],
  blocksLayout: number[],
  deviceType: DeviceType,
) => {
  let currentGridItems: ReactElement[] = [];
  let currentLayoutType = blocksLayout?.[0];

  const grids: ReactElement[] = [];

  forEach(htmlContent, (contentItem, index) => {
    const layoutType = blocksLayout[index];
    // If layoutType changes or the current grid is full
    if (
      layoutType !== currentLayoutType ||
      currentGridItems.length >= layoutType
    ) {
      // Add the current grid to the list of grids
      grids.push(
        <div
          key={`grid-${index}`}
          className={classNames(
            "grid gap-4 grid-cols-auto-fit-minmax-200",
            deviceType !== DEVICE_TYPE.MOBILE &&
              (currentLayoutType === 1
                ? "sm:grid-cols-1"
                : currentLayoutType === 2
                  ? "sm:grid-cols-2"
                  : "sm:grid-cols-3"),
          )}
        >
          {currentGridItems}
        </div>,
      );

      // Reset current grid items and update layout type
      currentGridItems = [];
      currentLayoutType = layoutType;
    }

    // Add the contentItem to the current grid
    currentGridItems.push(getContent(contentItem));

    // Handle the last grid which may not be added in the loop
    if (index === htmlContent.length - 1) {
      grids.push(
        <div
          key={`grid-${index}-end`}
          className={`grid grid-cols-${currentLayoutType} gap-4`}
        >
          {currentGridItems}
        </div>,
      );
    }
  });

  return grids;
};
