import classNames from "classnames";
import { useEffect, useState, useRef } from "react";
import Tooltip from "components/Tooltip";

const TextArea = (props: any) => {
  const {
    labelSize,
    LeadingIcon,
    TrailingIcon,
    defaultValue,
    error,
    disabled,
    leadingAddon,
    placeholder,
    label,
    value = "",
    onChange,
    rows,
    minLength,
    maxLength,
    required,
    tooltip,
  } = props;
  const textAreaRef = useRef<any>(null);
  const [textAreaValue, setTextAreaValue] = useState<string>("");

  useEffect(() => {
    required && !textAreaValue
      ? textAreaRef?.current?.setCustomValidity("This is a required field")
      : minLength && textAreaValue.length < minLength
        ? textAreaRef?.current?.setCustomValidity(
            `This field requires ` + minLength + ` or more characters`,
          )
        : textAreaRef?.current?.setCustomValidity("");
  }, [textAreaValue]);

  useEffect(() => {
    if (value !== textAreaValue) {
      setTextAreaValue(value);
    }
  }, [value]);

  return (
    <div className="w-full">
      {(label || required) && (
        <label
          htmlFor="textarea"
          className={`block font-medium text-gray-700 mb-2 text-${labelSize}`}
          data-testid="input-label"
        >
          {label}
          {required && "*"}
          {tooltip && <Tooltip tooltipId={label} content={tooltip} />}
        </label>
      )}
      <textarea
        data-testid="textArea"
        rows={rows}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        ref={textAreaRef}
        defaultValue={defaultValue}
        id="textarea"
        placeholder={placeholder}
        className={classNames(
          "pl-2 pr-0.5 pt-1.5 border w-full max-w-[25rem] font-normal text-sm focus:outline-none resize-none justify-center bg-white",
          LeadingIcon ? "pl-10" : "",
          TrailingIcon ? "pr-10" : "",
          error
            ? "focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300"
            : "focus:ring-gray-500 focus:border-gray-500 border-gray-300 placeholder-gray-300 text-gray-900 ",
          leadingAddon ? "rounded-r-md rounded-none" : "rounded-md",
        )}
        onChange={(e) => {
          setTextAreaValue(e.target.value);
          onChange(e.target.value);
        }}
        disabled={disabled}
        value={textAreaValue}
      />
    </div>
  );
};

TextArea.defaultProps = {
  labelSize: "sm",
};
export default TextArea;
